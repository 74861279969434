import { render, staticRenderFns } from "./BuyerTimePassedInfo.vue?vue&type=template&id=0ff59cef&scoped=true&"
var script = {}
import style0 from "@assets/css/buyer-job-text.css?vue&type=style&index=0&id=0ff59cef&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ff59cef",
  null
  
)

export default component.exports